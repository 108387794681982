.hello{
    margin-top:1rem;
  }
  .checkoutProduct {
      display: flex;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .checkoutProduct-image {
      object-fit: contain;
      width: 180px;
      height: 180px;
    }
    .checkoutProduct-info {
      padding-left: 20px;
    }
    .checkoutProduct-info > button {
      background-color: #f0c14b;
      border: 1px solid;
      margin-top: 10px;
      border-color: #9c7e31 #846a29;
      color: #111;
      cursor: pointer;
      width: 150px;
      height: 35px;
      border-radius: 5px;
      transition: transform 0.3s ease;
    }
    .checkoutProduct-info > button:active {
      transform: scale(0.8);
    }
    .checkoutProduct-title {
      font-size: 17px;
      font-weight: 800;
    }
    
  
    .subtotal {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 250px;
      padding: 20px;
      background-color: #f3f3f3;
      border: 1px solid #dddddd;
      border-radius: 3px;
    }
    .danger{
      color: rgb(135, 43, 43);
    }
    .subtotal__gift {
      display: flex;
      align-items: center;
    }
    .subtotal__gift > input {
      margin-right: 5px;
    }
    .subtotal > button {
      background-color: #f0c14b;
      border-radius: 2px;
      width: 100%;
      height: 50px;
      border: 1px solid;
      border-color: #a88734 #9c7e31 #846a29;
      color: #111;
      cursor: pointer;
    }
    
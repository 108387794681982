.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    background-color: white;
  }
  .login-logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
  .login-container {
    width: 25rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
  }
  .login-container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
  }
  .login-container > form > h5 {
    margin-bottom: 5px;
  }
  .login-container > form > input {
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
  }
  .login-container p {
    font-size: 12px;
    margin-top: 15px;
  }
  .login-signInBtn {
    background-color: #cd9042;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    cursor: pointer;
    width: 100%;
    height: 30px;
  }
  .login-registerBtn {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid darkgray;
    margin-top: 10px;
    cursor: pointer;
  }
  
.name
{
    font-size: 30px;
    font-weight: bold;
}   
.desc{
    letter-spacing: 2px;

}

.price
{
    color: red;
    font-size: 20px;
}

.menu{
    height: 70vh;
    border: 1px solid grey;
}

.menu input{
    width: 50px;
}

.menu .pp
{
    color: gray;
}

.menu .l
{
    color: blue;
}